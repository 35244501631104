@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
  --color-primary: #6B7A8F;
  --color-secondary: #101118;
  --color-accent: #1D1F2F;
  --color-focus: #6D64F7;
  --color-lightPink: #ffaad0; 
  --color-darkPink: #ec3e7f; 
  --color-teal: #41d8d0;
  --color-darkPurple: #663399; 
  --color-lightPurple: #7851a8; 
  --color-darkGreen: #3eb488; 
  --color-lightGreen: #aaf0d0;
  --base-duration: 600ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);


}
html{
  scroll-behavior: smooth;
}


body {
 
  color: rgb(var(--foreground-rgb));
  font-family: Arial,Helvetica,sans-serif;
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}

@keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 1;}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


@font-face {
  font-family: "sunnyspells";
  src: url("../assets/SunnyspellsRegular-MV9ze.otf");
}
@font-face {
  font-family: "fredoka";
  src: url("../assets/Fredoka-VariableFont_wdth\,wght.ttf");
}

.verification-page {
  @apply flex justify-center items-start h-screen overflow-auto;
  margin-top: 2%;
}

.verification-container {
  @apply h-fit md:w-4/6 sm:w-fit pb-10 flex flex-col justify-center items-center overflow-auto rounded;
  border: 5px solid rgba(249, 120, 163, 1);
  background-color:white;
}

.has-shadow {
  @apply shadow-md hover:drop-shadow-xl;
}